import React from "react";
import "./Loader.css";

function Loader() {
  return (
    <div id="container3">
      <img className="preloader" src="/assets/dna3.gif" />
    </div>
  );
}

export default Loader;
